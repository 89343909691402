.lang-btns {
  display: flex;
  flex-direction: column;
  left: 3rem;
  top: -5rem;
  position: absolute;
}

.lang-btns button {
  background-color: inherit;
  border: none;
  border-bottom: 1px solid rgb(222, 215, 215);
  color: rgb(222, 215, 215);
  margin-bottom: 1rem;
  font-size: 17px;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 0;
  padding: 10px;
}

.lang-btns button:hover {
  /* background-color: white; */
  color: white;
}

.lang-btns button:focus {
  transform: translateX(1rem);
  color: white;
}
