.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 6rem;
}

.f-second{
    display: flex;
}

.footer h3{
    color:#5B8A8D;
    font-size: 17px;
}

.footer p{
    color: #5B8A8D;
    margin-right: 16px;
}

.footer img{
    margin-right: 1rem;
}