.p-card {
  width: 200px;
}

.p-card img {
    width: 100%;
    border-radius: 25px;
    background-color: pink;
    transition: 0.4s;
}
.p-card img:hover{
  transform: scale(1.08);
}
.c-bottom p {
  text-align: center;
}

.wrap-cards{
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10rem;
}

.Category h1{
    font-size: 50px;
    margin: 5rem 0;
    text-align: center;
}