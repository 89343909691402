.modal {
  padding: 20px;
  border-radius: 15px;
  /* background-color:rgba(243, 247, 250, 0.7); */
  backdrop-filter: blur(25px);
  background-color: rgba(34, 2, 110, 0.5);
  
}

.form-input {
  padding: 7px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid black;
  width: 70%;
  color: black;
}
.close {
  padding: 7px 12px;
  border-radius: 50%;
  background-color: black;
  color: white;
  border: none;
  margin-bottom: 10px;
  /* float: right; */
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.btn-wrap {
  display: flex;
  justify-content: end;
  align-items: center;
}

.form-btn {
  padding: 10px 35px;
  border-radius: 15px;
  background-color: black;
  color: white;
  border: none;
  margin-bottom: 10px;
  margin-top: 10px;
  /* float: right; */
  cursor: pointer;
  width: 70%;
  
}

.form-btn:hover,
.close:hover {
  background-color: white;
  color: black;
  transition: 0.3s;
}

section button{
    color: black;
    border: 1px solid black;
    margin-top: 1rem;
    transition: 0.4s;
    font-size: 20px;
    padding: 10px 25px;
}

section button:hover{
    background-color: #5b8a8d;
    color: white;
}
.form-text{
    width: 70%;
}