.contact {
  display: flex;
  justify-content: space-between;
  background-color: #5b8a8d;
}

.c-left form {
  display: flex;
  flex-direction: column;
}

.c-left h1 {
  color: #feebe2;
  font-size: 50px;
  margin-top: 5rem;
  /* width: 28rem; */
  margin-left: 12rem;
  margin-bottom: 2rem;
}

.inp {
  padding: 20px 35px;
  border: 1px solid white;
  background-color: inherit;
  border-radius: 12px;
  color: white;
  width: 100%;
  margin-bottom: 16px;
  outline: none;
}

input::placeholder {
  color: white;
}

.c-left form {
  margin-left: 12rem;
}
